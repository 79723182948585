export function CasinoIconOld() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_442_84940)">
        <path
          d="M13.0978 1.44763L7.81857 0.516768C7.2248 0.412069 6.65856 0.808553 6.55386 1.40232L6.50391 1.68569C7.26737 1.77975 7.91238 2.32974 8.11546 3.08757L8.43809 4.29166C8.60891 4.21758 8.80256 4.19089 8.99965 4.22565C9.37363 4.2916 9.66055 4.56074 9.76744 4.89945C9.98373 4.61773 10.3454 4.46294 10.7194 4.52889C11.2546 4.62328 11.612 5.13371 11.5177 5.66896C11.3126 6.832 10.0645 7.7668 9.47223 8.15128L10.3765 11.5261C10.425 11.707 10.4452 11.8889 10.4407 12.0673L11.2016 12.2014C11.7953 12.3061 12.3616 11.9096 12.4663 11.3159L13.9833 2.71239C14.088 2.11857 13.6915 1.55233 13.0978 1.44763Z"
          fill="white"
        />
        <path
          d="M7.3245 3.29954C7.19379 2.81178 6.75271 2.49008 6.27067 2.49011C6.17715 2.49011 6.08205 2.50223 5.98741 2.52757L0.809438 3.915C0.227044 4.07105 -0.118581 4.66968 0.0374693 5.25208L2.29855 13.6906C2.42925 14.1784 2.87034 14.5 3.35238 14.5C3.4459 14.5 3.541 14.4879 3.63561 14.4626L8.81358 13.0751C9.39598 12.9191 9.7416 12.3204 9.58555 11.738L7.3245 3.29954ZM3.2952 9.06773C3.2074 8.99049 3.17462 8.86807 3.21202 8.7573L4.00379 6.4129C4.07002 6.21679 4.31452 6.15128 4.46995 6.288L6.32784 7.92244C6.41565 7.99969 6.44843 8.1221 6.41102 8.23287L5.61926 10.5773C5.55303 10.7734 5.30852 10.8389 5.1531 10.7022L3.2952 9.06773Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_442_84940">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CasinoIconNew() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.57904 9.17384C8.63873 8.48682 10.2559 10.1789 10.1535 12.5139C10.045 14.9891 8.10537 17 5.57904 17C3.05272 17 1.11307 14.9891 1.00458 12.5139C0.902232 10.1789 2.51936 8.48682 5.57904 9.17384ZM9.39303 9.13529C10.1293 8.57154 11.2223 8.35404 12.6381 8.67245C15.5532 8.01821 17.0932 9.63118 16.9956 11.8549C16.8927 14.2143 15.044 16.1301 12.6381 16.1301C11.6749 16.1338 10.7394 15.8138 9.98739 15.2235C10.4991 14.416 10.7882 13.4921 10.8266 12.5422C10.8816 11.3103 10.5055 10.2176 9.77094 9.46587C9.65357 9.34656 9.52725 9.23606 9.39303 9.13529ZM10.2709 1.03652C10.3807 0.876902 10.9311 1.28352 11.0601 1.42867C11.122 1.49844 11.1531 1.58948 11.1465 1.68174C11.14 1.774 11.0963 1.85994 11.0252 1.92063C10.6626 2.22995 10.3188 2.54413 9.98976 2.86159C9.989 2.86597 9.9893 2.87026 9.98836 2.87464C9.65781 4.42528 10.6406 5.60713 11.5911 6.74999C11.9288 7.13805 12.2408 7.54692 12.5252 7.97412C12.216 7.91542 11.9026 7.87991 11.5879 7.86787C11.4184 7.64618 11.231 7.42041 11.0401 7.1908C10.2048 6.18644 9.28012 5.06995 9.21667 3.64751C7.78307 5.18592 6.73781 6.79624 6.12477 8.40755C5.94554 8.42996 5.76394 8.45803 5.57899 8.49575C5.50299 8.48026 5.42809 8.46793 5.35299 8.45501C5.98769 6.64458 7.13206 4.83854 8.7367 3.12756C8.89766 2.95746 9.04669 2.77686 9.18275 2.58701C9.54555 2.07606 9.91102 1.55964 10.2709 1.03652ZM11.2069 2.27259C11.526 2.22215 13.7568 2.36642 14.7301 3.08625C15.9167 3.96378 15.87 4.29984 16.3614 6.60453C14.9997 6.37228 13.8763 6.30546 12.4083 5.02649C11.6133 4.32722 11.1736 3.3194 11.2069 2.27259Z"
        fill="white"
      />
    </svg>
  );
}
